import React from 'react';
import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';
import { BrandLogosType } from 'components/models/interface-models';


import './Partners.scss';



const Partners = () => {
    
    const { t } = useTranslation(["homeTranslations"]);
    const  itsaIMG                  =`${CDN_HOST}/images/itsa.png`,
           universidadMagdalenaIMG  =`${CDN_HOST}/images/universidad-del-magdalena.png`,
           universidadNorteIMG      =`${CDN_HOST}/images/universidad-del-norte.png`,
           aliticIMG                =`${CDN_HOST}/images/fundacion-alitic.png`,
           universidadNacionalIMG   =`${CDN_HOST}/images/universidad-nacional-de-ingenieria.png`,
           uniNaCostaRicaIMG = `${CDN_HOST}/images/universidad-nacional-costa-rica.png`,
           uniSalvadorIMG = `${CDN_HOST}/images/universidad-del-savador.png`,
           uamIMG = `${CDN_HOST}/images/UAM.png`,
           moonPreneurIMG = `${CDN_HOST}/images/moonpreneur-logo.png`,
           uniSanCarGuaIMG = `${CDN_HOST}/images/universidad-san-carlos-guatemala.png`;


    const partnersBrands: BrandLogosType[] = [
        {
            image: uniNaCostaRicaIMG,
            brand: "Universidad Nacional Costa Rica"
        },
        {
            image: uamIMG,
            brand: "Universidad Americana"
        },
        {
            image: uniSalvadorIMG,
            brand: "Universidad del Salvador"
        }, 
        {
            image: moonPreneurIMG,
            brand: "Moon Preneur"
        }, 
        {
            image: uniSanCarGuaIMG,
            brand: "Universidad San Carlos Guatemala"
        }, 
        {
            image: itsaIMG,
            brand: "Itsa"
        }, 
        {
            image: universidadMagdalenaIMG,
            brand: "Universidad Magdalena"
        }, 
        {
            image: universidadNorteIMG,
            brand: "Universidad del Norte"
        }, 
        {
            image: aliticIMG,
            brand: "Alitic"
        }, 
        {
            image: universidadNacionalIMG,
            brand: "Universidad Nacional de Ingeniería"
        }
    ];

    return (
        <section className="Partners">
            <div className="wrapper">
                <h3>{t("partnersTitle")}</h3>
                <div className="partner__brands-wrapper">
                    {partnersBrands.map(({image, brand}, index) => (
                        <figure key={brand + index} className="partner__brand">
                            <img src={ image } alt={brand} title={brand} width="90" height="72" loading="lazy" decoding='async' />
                        </figure>
                    ))}
                </div>
            </div>
        </section>
    )
};


export default Partners;