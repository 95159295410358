import React from 'react';
import { useTranslation } from 'react-i18next';
import './PartnerBenefits.scss';

interface ParnterBenefitsTypes {
    title: string;
    descriptionOne: string;
    descriptionTwo: string;
    tableBenefits: string[];
}

const PartnerBenefits = ({
    title,
    descriptionOne,
    descriptionTwo,
    tableBenefits
}: ParnterBenefitsTypes) => {
    const { t } = useTranslation(["partnersAndSponsors"]);
  return (
    <section className='PartnerBenefits'>
        <div className="wrapper">
                <h2>{t(`${title}`)}</h2>
                <p>{t(`${descriptionOne}`)}</p>
                <p>{t(`${descriptionTwo}`)}</p>

                <div className="table-benefits">
                    <h3>{t('partnershipBenefits')}</h3>
                    <h3>{t('memberBenefits')}</h3>
                   {tableBenefits.map(benefit => (
                       <p>{t(`${benefit}`)}</p>
                   ))}
                </div>
        </div>
    </section>
  )
}

export default PartnerBenefits