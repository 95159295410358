import React from "react";
import { CDN_HOST } from "env/env.dev";
import { useTranslation } from "react-i18next";

import "./Events.scss";

const Events = () => {
  const { t } = useTranslation(["homeTranslations"]);
  const eventImage = `${CDN_HOST}/images/megaevent-comingsoon-large.webp`;
  const registerForm =
    "https://www.eventbrite.com/e/the-future-of-work-for-latinx-tickets-268475366047?aff=ebdsoporgprofile";

  return (
    <section className="Events">
      <div className="wrapper">
        <div className="Events__title">
          <h4>{t("eventsTitle")}</h4>
          <h3>{t("eventsSubtitle")} {t("eventsSubtitleSecond")}</h3>
          <br />
        </div>

        <picture className="Event__image">
          <source media="(max-width: 768px)" srcSet="https://cdn.sonarescrecer.org/images/megaevent-comingsoon-small.webp" />
          <source media="(min-width: 769px)" srcSet="https://cdn.sonarescrecer.org/images/megaevent-comingsoon-large.webp" />
          <img src={eventImage} alt="Feature Event" loading="lazy" width="336" height="284" />
        </picture>

        <div className="Event__content-wrapper">
          <p>{t("eventsContent")}</p>
          <br />

          {/* <p style={{ 
              textAlign: 'center',
              fontSize: '24px',
              textTransform: 'uppercase',
              color: '#d62246',
              boxShadow: '-2px 6px 10px #E2E3E3',
              padding: '20px 0'
             }}><strong>{ t("eventMessage") }</strong></p> */}
          <div className="Event_coming-soon">
            <p>{t("eventsSoon")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Events;
