import React from 'react';
import { useTranslation } from 'react-i18next';
import { DreamerCard,
MentorCard,
VolunteerCard } from '../../atoms';


import './HomeMembers.scss';

const HomeMembers = () => {
    const { t } = useTranslation(["homeTranslations"]);

    
    return (
        <section className="HomeMembers">
            <div className="wrapper">
                <h3 className="home__members-title">{t("cardsMembersTitle")}</h3>
                <div className="members__cards-wrapper">
                    <DreamerCard />
                    <MentorCard />
                    <VolunteerCard />
                </div>
            </div>
        </section>
    )
};

export default HomeMembers;
