import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { Elements,
    CardElement,
    useStripe,
    useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { STRIPE_PUBLISHABLE_KEY, PAYPAL_CLIENT_ID, CDN_HOST } from 'env/env.dev.js';
import { Link } from 'react-router-dom';

import './PaymentForm.scss';


const stripePublicKey = () => {
   if(process.env.NODE_ENV === "production") {
       return [
           STRIPE_PUBLISHABLE_KEY,
           PAYPAL_CLIENT_ID
        ];
   } else {
       return [
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
            process.env.REACT_APP_PAYPAL_CLIENT_ID
        ];
   }
}

const [STRIPE_PUBLIC_KEY, PAYPAL_ID ] = stripePublicKey();
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

type FormValues = {
    amount: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    emailConfirm: string;
    country: string;
    address1: string;
    address2: string;
    address3: string; 
    city: string;
    state: string;
    zipCode: number;
}

const CheckoutForm = () => {

    const { t, i18n } = useTranslation(["paymentDetails"]),
          { register, formState: { errors }, handleSubmit } = useForm<FormValues>(),
          [amountToPay, setAmountToPay] = useState(0),
          [cardPayment, setCardPayment] = useState(true),
          [isLoading, setIsLoading] = useState(false),
          [succesfulPayment, setSuccessfulPayment] = useState(false),
          [errorPayment, setErrorPayment] = useState(false),
          [warningMessage, setWarningMessage] = useState(false),
          [countryValue, setCountryValue] = useState(''),
          [baseURL, setBaseURL] = useState(''),
          isEnglish = i18n.language === 'en',
          countryOptions = useMemo(() => countryList().getData(), []);

    const cardsContainerRef = useRef<HTMLDivElement>(),
          $10cardRef = useRef<HTMLDivElement>(),
          $20cardRef = useRef<HTMLDivElement>(),
          $50cardRef = useRef<HTMLDivElement>(),
          $100cardRef = useRef<HTMLDivElement>(),
          submitButtonRef = useRef<HTMLButtonElement>(),
          successMessageRef = useRef<HTMLDivElement>();

    const  masterCardIMG        = `${CDN_HOST}/images/mastercard.png`,
           visaIMG              = `${CDN_HOST}/images/visa.png`,
           americaExpressIMG    = `${CDN_HOST}/images/american-express.png`,
           discoverIMG          = `${CDN_HOST}/images/discover.png`,
           womanIMG             = `${CDN_HOST}/images/woman.png`,
           successIMG           = `${CDN_HOST}/images/teenagers-group.png`;

    const grayColor     = '#F3F6F6',
          blueColor     = '#00B4D8',
          whiteColor    = '#fff',
          blackColor    = '#000',
          pinkColor     = '#d62246',
          darkBlueColor = '#00333d';


    const stripe =  useStripe();
    const elements = useElements();  

    const CARD_OPTIONS = {
        hidePostalCode: true,
        icon_style: "solid",
        style: {
            base: {
                fontSize: "16px"
            }
        }
    }

    useEffect(() => {
        (process.env.NODE_ENV === "production")
            ? setBaseURL('https://api.sonarescrecer.org')
            : setBaseURL('http://localhost:8080');
    }, []);

    useEffect(() => {
        if(cardPayment) {
                const submitButton = submitButtonRef.current;
                if(!succesfulPayment) {
                    (!stripe || isLoading)
                    ?  submitButton.style.opacity = '0.6'
                    :  submitButton.style.opacity = '1';
                }
            }
        
    }, [stripe, isLoading, succesfulPayment, cardPayment]);
    
    useEffect(() => {
        
        if(cardsContainerRef.current) {
            const teenDollars = $10cardRef.current;
            const twentyDollars = $20cardRef.current;
            const fiftyDollars = $50cardRef.current;
            const oneHundredDollars = $100cardRef.current;
            if(amountToPay === 10) {
                teenDollars.style.backgroundColor = blueColor;
                teenDollars.style.color = whiteColor;

                twentyDollars.style.backgroundColor = grayColor;
                twentyDollars.style.color = blackColor;

                fiftyDollars.style.backgroundColor = grayColor;
                fiftyDollars.style.color = blackColor;

                oneHundredDollars.style.backgroundColor = grayColor;
                oneHundredDollars.style.color = blackColor;

            } else if (amountToPay === 20) {
                teenDollars.style.backgroundColor = grayColor;
                teenDollars.style.color = blackColor;

                twentyDollars.style.backgroundColor = blueColor;
                twentyDollars.style.color = whiteColor;

                fiftyDollars.style.backgroundColor = grayColor;
                fiftyDollars.style.color = blackColor;

                oneHundredDollars.style.backgroundColor = grayColor;
                oneHundredDollars.style.color = blackColor;

            } else if (amountToPay === 50) {
                teenDollars.style.backgroundColor = grayColor;
                teenDollars.style.color = blackColor;

                twentyDollars.style.backgroundColor = grayColor;
                twentyDollars.style.color = blackColor;

                fiftyDollars.style.backgroundColor = blueColor;
                fiftyDollars.style.color = whiteColor;

                oneHundredDollars.style.backgroundColor = grayColor;
                oneHundredDollars.style.color = blackColor;
            } else if(amountToPay === 100) {
                teenDollars.style.backgroundColor = grayColor;
                teenDollars.style.color = blackColor;

                twentyDollars.style.backgroundColor = grayColor;
                twentyDollars.style.color = blackColor;

                fiftyDollars.style.backgroundColor = grayColor;
                fiftyDollars.style.color = blackColor;

                oneHundredDollars.style.backgroundColor = blueColor;
                oneHundredDollars.style.color = whiteColor;
            } else {
                teenDollars.style.backgroundColor = grayColor;
                teenDollars.style.color = blackColor;

                twentyDollars.style.backgroundColor = grayColor;
                twentyDollars.style.color = blackColor;

                fiftyDollars.style.backgroundColor = grayColor;
                fiftyDollars.style.color = blackColor;

                oneHundredDollars.style.backgroundColor = grayColor;
                oneHundredDollars.style.color = blackColor;
            }
        }
    }, [amountToPay]);

    useEffect(() => {
        const successView = successMessageRef.current
        if(succesfulPayment){
            successView.scrollIntoView();
        }
    }, [succesfulPayment]);

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: `1px solid ${blueColor}`,
          color: state.isSelected ? pinkColor : blackColor,
          backgroundColor: state.isSelected ? grayColor : whiteColor,
          padding: 20,
        }),
        control: () => ({
            border: `1px solid ${darkBlueColor}`,
            width: "92%",
            maxWidth: "398px",
            borderRadius: "5px",
            display: "flex",
            marginBlock: "10px",
          }),
      }

    const countryHandler = (country) => {
        setCountryValue(country);
    };

    const handleSubmitForm = async (dataUser) => {
        setIsLoading(true);
        const {
            firstName,
            lastName,
            emailAddress,
            address1,
            address2,
            city,
            state,
        } = await dataUser;
    
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            billing_details: {
                address: {
                    city: city,
                    line1: address1,
                    line2: address2,
                    state: state
                },
                email: emailAddress,
                name: firstName + " " + lastName,
            },
            type: 'card',
            card: elements.getElement(CardElement),
        });
    
        if (!error) {
            const { id } = paymentMethod;
    
            try {
                if (amountToPay) {
                    if (amountToPay !== 0) {
                        setWarningMessage(false);
                        setErrorPayment(false);
    
                        // Utilizamos fetch
                        const response = await fetch(`${baseURL}/api/payment`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                id,
                                amount: amountToPay * 100,
                            }),
                        });
    
                        if (response.ok) {
                            const paymentResponse = await response.json();
                            console.log({ paymentResponse });
                            elements.getElement(CardElement).clear();
    
                            if (paymentResponse) {
                                setSuccessfulPayment(true);
                            }
                        } else {
                            console.log('Lo sentimos, ocurrió un error con el pago');
                        }
                    } else {
                        console.log("El monto debe ser mayor a 0");
                    }
                } else {
                    setWarningMessage(true);
                    console.log("Por favor, ingresa un monto a donar");
                }
            } catch (error) {
                setErrorPayment(true);
    
                if (error) {
                    console.log('Lo sentimos, ocurrió un error con el pago');
                }
    
                console.error(error);
            }
    
            setIsLoading(false);
        } else {
            setErrorPayment(true);
            console.log('Lo sentimos, ocurrió un error con el pago');
            setIsLoading(false);
        }
    }

    return(
        <section className="PaymentForm">
        <h2 ref={successMessageRef}>{t("title")}</h2>
            {(!succesfulPayment) && (
                <>
                    <figure className='woman-cloud__desktop'>
                        <img src={womanIMG} alt="Woman on a cloud" />
                    </figure>

                    <form className='form' onSubmit={handleSubmit(handleSubmitForm)}>
                        <h4>{t("amount")}</h4>

                        <div ref={cardsContainerRef} className="amount-cards">
                            <div className="card" 
                                onClick={() => setAmountToPay(10)}
                                ref={$10cardRef}><p>{"$ 10"}</p>
                            </div>
                            <div className="card" 
                                onClick={() => setAmountToPay(20)}
                                ref={$20cardRef}><p>{"$ 20"}</p>
                            </div>
                            <div className="card" 
                                onClick={() => setAmountToPay(50)}
                                ref={$50cardRef}><p>{"$ 50"}</p>
                            </div>
                            <div className="card" 
                                onClick={() => setAmountToPay(100)}
                                ref={$100cardRef}><p>{"$ 100"}</p>
                            </div>

                        </div>

                        <p className='or-break'>{t("or")}</p>

                        <br />

                        <label htmlFor="amount">
                            <span>{t("amountChoice")}</span>
                            <div className='amount-container'>
                                <span className='dollar'>$</span>
                                <input 
                                    type="number" 
                                    id="amount" 
                                    {...register("amount", 
                                    { valueAsNumber: true })} 
                                    value={amountToPay}
                                    onChange={(e) => setAmountToPay(parseInt(e.target.value))}/>
                            </div>
                        </label>

                        <br />

                        <h4>{t("billingInfo")}</h4>

                        <section className='billingInformation'>
                            <div className='boxContainer'>
                                <p className='text'>
                                {t("text01")} </p>
                                <p className='text'>{t("text02")}
                                <Link className='privacy' to="/privacy-policy" aria-label="Privacy Policy" rel="noopener noreferrer">
                                    {t("footer:privacyPolicy")}
                                </Link>
                                {isEnglish && ' guidelines'}
                                {'.'}
                                </p>
                            </div>
                        </section>

                        <h5>{t("details")}</h5>

                        <label htmlFor="firstName">
                            {t("fname")}
                            <input 
                                type="text" 
                                id="firstName" 
                                {...register('firstName', 
                                { minLength: 2, required: true})} />
                                {errors.firstName && <p>{t("inputRequire")}</p>}
                        </label>

                        <label htmlFor="lastName">
                            {t("lname")}
                            <input 
                                type="text" 
                                id="lastName" 
                                {...register("lastName", 
                                { minLength: 2, required: true})}/>
                                {errors.lastName && <p>{t("inputRequire")}</p>}
                        </label>

                        <label htmlFor="emailAddress">
                            {t("email")}
                            <input 
                                type="email" 
                                id="emailAddress" 
                                {...register("emailAddress", { required: true })} />
                                {errors.emailAddress &&  <p>{t("inputRequire")}</p>}
                        </label>

                        <label htmlFor="emailConfirm">
                            {t("emailConfirm")}
                            <input 
                                type="email" 
                                id="emailConfirm"
                                {...register("emailConfirm", { required: true })} 
                                {...errors.emailConfirm && <p>{t("inputRequire")}</p>}
                                />
                        </label>

                        <h5>{t("address")}</h5>

                        <label htmlFor="country">
                            {t("country")}
                            <Select 
                                styles={customStyles}
                                options={countryOptions} 
                                value={countryValue}
                                {...register("country")}
                                onChange={countryHandler} />
                            {errors.country &&  <p>{t("inputRequire")}</p>}
                        </label>

                        <label htmlFor="address1">
                            {t("address1")}
                            <input 
                                type="text" 
                                id="address1" 
                                {...register("address1", { minLength: 10, required: true})} />
                                {errors.address1 &&  <p>{t("inputRequire")}</p>}
                        </label>

                        <label htmlFor="address2">
                            {t("address2")}
                            <input 
                                type="text" 
                                id="address2" 
                                {...register("address2")} />
                        </label>

                        <label htmlFor="address3">
                            {t("address3")}
                            <input 
                                type="text" 
                                id="address3" 
                                {...register("address3")} />
                        </label>

                        <label htmlFor="city">
                            {t("city")}
                            <input 
                                type="text"  
                                id="city" 
                                {...register("city", { required: true, minLength: 2 })} />
                                {errors.city &&  <p>{t("inputRequire")}</p>}
                        </label>

                        <div className="state-and-code">
                             <label htmlFor="state">
                                  {t("state")}
                                  <input 
                                     type="text" 
                                     id="state" 
                                     {...register("state")} />
                                     {errors.state &&  <p>{t("inputRequire")}</p>}
                             </label>

                             <label htmlFor="zipCode">
                                  {t("zipCode")}
                                  <input 
                                     type="text" 
                                     id="zipCode" 
                                     {...register("zipCode", { minLength: 3 })} />
                                     {errors.zipCode &&  <p>{t("inputRequire")}</p>}
                             </label>
                        </div>

                        <h5>{t("paymentInfo")}</h5>

                        { cardPayment && (
                            <>
                                <div className="cards-container">
                                    <figure className="card">
                                        <img src={masterCardIMG} alt="Master Card" />
                                    </figure>
                    
                                    <figure className="card">
                                        <img src={visaIMG} alt="Visa" />
                                    </figure>
                    
                                    <figure className="card">
                                        <img src={americaExpressIMG} alt="American Express" />
                                    </figure>
                    
                                    <figure className="card">
                                        <img src={discoverIMG} alt="Discover" />
                                    </figure>
                                </div>
                            
                                <div className="cardInfo">
                                     <CardElement options={CARD_OPTIONS} className='CardElement'/>
                                </div>
                            </>
                        )}

                        
                    
                        <div className="resume-bill__cloud">
                            <figure className='woman'>
                                <img src={womanIMG} alt="Woman on cloud" />
                            </figure>
                    
                            <div className="cloud-bill">
                                <div className="payment-total">
                                    <p>{t("paymentAmount")}</p>
                                    <p>{`$${amountToPay}`}</p>
                                </div>
                                <div className="payment-recurrencing">
                                    <p>{t("recurring")}</p>
                                    <p>{t("oneTime")}</p>
                                </div>
                            </div>
                        </div>
                            
                        {cardPayment && (
                            <button
                                ref={submitButtonRef} 
                                className="btn-primary" 
                                type="submit" 
                                disabled={!stripe}>{t("submitPayment")}
                                {isLoading && <div className='loader'></div>}
                            </button>
                        )}
                                

                    </form>
                    <div className="cloud-bill__desktop">
                        <div className="payment-total">
                            <p>{t("paymentAmount")}</p>
                            <p>{amountToPay 
                                ? `$${amountToPay}`
                                : `$0`}</p>
                        </div>
                        <div className="payment-recurrencing">
                            <p>{t("recurring")}</p>
                            <p>{t("oneTime")}</p>
                        </div>
                    </div>
                </>
            )}

            {succesfulPayment && (
                <>
                    <div className='SuccessMessage'>
                        <h3> <i className='success-icon'></i> {t("successMessage")}</h3>
                        <p>{t("successMessageContent")}<strong> ${amountToPay}USD</strong></p>
                    </div>

                    <figure className="success-image">
                        <img src={successIMG} alt="Thanks for support us" />
                    </figure>
                </>
            )}
            

            {errorPayment && (
                <div className="ErrorMessage">
                    <h3> <i className='error-icon'></i> {t("errorMessage")}</h3>
                    <p>{t("errorMessageContent")}</p>
                </div>
            )}

           {warningMessage && (
                <div className="WarningMessage">
                    <h3> <i className='warning-icon'></i> {t("warningMessage")}</h3>
                </div>
           )}

           
    </section>
    )
}


const PaymentForm = () => {

    return (
        <Elements stripe={stripePromise} >
             <CheckoutForm />
        </Elements>
    )
}

export default PaymentForm;
