import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import "./Banner.scss";
import  useBreadcrumbs from "use-react-router-breadcrumbs";

const Banner = () => {
  const routas:any =  [{
    path: '/', 
    breadcrumbs: 'Home'
  }
];
  const breadcrumbs = useBreadcrumbs(routas, { excludePaths: ['/']});

  return (
    <div className="Banner">
      <div className="banner-path">
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <>
            <span key={match.pathname}>
              <Link to={match.pathname}>
                {breadcrumb}
              </Link>
            </span>
            <IoIosArrowForward size="13" />
          </>
        ))}

        {/* <Link to="/">Home </Link>
        
        <Link to={`/${title.toLowerCase().replace(" ", "-")}`}> {title}</Link> */}
      </div>
    </div>
  );
};

export default Banner;
