import React from 'react';
import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';

import './DreamerSection.scss';
import { Link } from 'react-router-dom';

interface DreamerSectionProps {
    formURL?: string
}

const DreamerSection = ({
    formURL
}: DreamerSectionProps) => {
    const { t } = useTranslation(["members"]);
    const DreamerIMG = `${CDN_HOST}/images/dreamer.png`;
   

    return (
        <section className="DreamerSection">
            <div className="wrapper">
                <div className="Dreamer__content">
                    <h2>{t("dreamerTitle")}</h2>
                    <p>{t("dreamerTextOne")}</p>
                    <p>{t("dreamerTextTwo")}</p>
        
                    <div className="button__container">
                        <p>{t("becomeButton")}</p>
                        <Link to={formURL} className="btn-primary" rel="noopener noreferrer">{t("joinButton")}</Link>
                    </div>
                </div>

                <div className="images-container">
                    <div className="Dreamer__image-container">
                        <figure className="Dreamer__image">
                            <img src={DreamerIMG} loading="lazy" alt="Dreamers" />
                        </figure>
                    </div>

                    <div className="techNode-section"></div>
                </div>
            </div>
        </section>
    )
}

export default DreamerSection;
