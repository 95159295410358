import React from 'react';
import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';
import { BrandLogosType } from 'components/models/interface-models';
import "./Sponsors.scss";

const Sponsors = () => {
    const { t } = useTranslation(["homeTranslations"]);
    const edxIMG = `${CDN_HOST}/images/edx.png`,
          uiPathIMG = `${CDN_HOST}/images/uipath.png`,
          sansIMG =`${CDN_HOST}/images/sans.png`,
          straExeSoIMG = `${CDN_HOST}/images/strategy-execution-solutions.png`,
          foFundIMG =`${CDN_HOST}/images/ForuneFund.png`,
          s2TechIMG = `${CDN_HOST}/images/S2Tech.png`,
          mcNshotIMG =`${CDN_HOST}/images/mc-nshot.png`,
          sansInsIMG = `${CDN_HOST}/images/sans-institute.png`;


    const sponsorsList: BrandLogosType[] = [
      {
        image: straExeSoIMG,
        brand: "Strategy Execution Solutions"
      },
      {
        image: foFundIMG,
        brand: "Forune Fund"
      },
      {
        image: s2TechIMG,
        brand: "S2 Tech"
      },
      {
        image: mcNshotIMG,
        brand: "Mc Nshot"
      },
      {
        image: sansInsIMG,
        brand: "Sans Institute"
      },
      {
        image: edxIMG,
        brand: "edx"
      },
      {
        image: uiPathIMG,
        brand: "ui Path" 
      },
      {
        image: sansIMG,
        brand: "Sans"
      },
    ];
  return (
    <section className="Sponsors">
        <div className="wrapper">
            <h3>{t("sponsorsTitle")}</h3>
            <div className="sponsors-section">
            { sponsorsList.map( ({image, brand}, index) => (
                        <figure key={brand + index} className="brand__supporter">
                            <img src={ image } alt={brand} title={brand} width="100" height="50" loading="lazy" decoding='async'/>
                        </figure>
            )) }
            </div>
        </div>
    </section>
  )
}

export default Sponsors