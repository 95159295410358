import React, { useState } from 'react';
import { CDN_HOST } from 'env/env.dev';
import { Link } from 'react-router-dom';

import './MentorCard.scss';
import { useTranslation } from 'react-i18next';

const MentorCard = () => {
    const { t } = useTranslation(["homeTranslations"]);
    const techRedIMG       = `${CDN_HOST}/images/tech-red.png`,
          techBlueIMG      = `${CDN_HOST}/images/tech-blue.png`,
          mentorGrayIMG    = `${CDN_HOST}/images/mentor-gray.png`,
          mentorColorIMG   = `${CDN_HOST}/images/mentor.png`;
    const [techIMG, setTechIMG] = useState(techBlueIMG);
    const [mentorIMG, setMentorIMG] = useState(mentorGrayIMG);

    const handleCardClosed = () => {
        setTechIMG(techBlueIMG);
        setMentorIMG(mentorGrayIMG);
    };

    const handleCardOpen = () => {
       
            setTechIMG(techRedIMG);
            setMentorIMG(mentorColorIMG);
    };


    return (
        <div onMouseOver={handleCardOpen} onMouseOut={handleCardClosed} className="MentorCard">
            <figure className="tech-image">
                <img src={techIMG} alt="Tech images" width="225" height="140" loading="lazy" />
            </figure>

            <h3>{t("cardTitleMentor")}</h3>

            <div id="mentor__content">
                <p>{t("cardContent")}</p>
                <Link to="/members/mentor" className="btn-secundary"rel="noopener noreferrer">{t("cardButton")}</Link>
            </div>

            <figure className="Mentor__image">
                <img src={mentorIMG} alt="Be a mentor"  width="308" height="312" loading="lazy"  />
            </figure>

        </div>
    )
};

export default MentorCard;