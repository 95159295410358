import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import { Footer } from "components/molecules";

import { AuthProvider } from "auth";

import "./App.scss";
import { LoadingAnimation, Loading } from "components/atoms";

const Home  = lazy(() => import("components/views/Home").then(({ Home }) => ({default: Home})));
const Donations = lazy(() => import("components/views/Donations").then(({ Donations }) => ({default: Donations})));
const PartnersAndSponsors = lazy(() => import("components/views/PartnersAndSponsors").then(({ PartnersAndSponsors }) => ({default: PartnersAndSponsors })));
const Dreamer = lazy(() => import("components/views/Dreamer").then(({ Dreamer }) => ({default: Dreamer})));
const Mentor = lazy(() => import("components/views/Mentor").then(({ Mentor }) => ({default: Mentor})));
const Volunteer = lazy(() => import("components/views/Volunteer").then(({ Volunteer }) => ({default: Volunteer})));
const EventsView = lazy(() => import("components/views/EventsView").then(({ EventsView }) => ({default: EventsView})));
const WhoWeAre = lazy(() => import("components/views/WhoWeAre").then(({ WhoWeAre }) => ({default: WhoWeAre})));
const ContactUs = lazy(() => import("components/views/ContactUs").then(({ ContactUs }) => ({default: ContactUs})));
const OurPrograms = lazy(() => import("components/views/OurPrograms").then(({ OurPrograms }) => ({default: OurPrograms})));
const PaymentDetails = lazy(() => import("components/views/PaymentDetails").then(({ PaymentDetails }) => ({default: PaymentDetails})));
const TermsAndConditions = lazy(() => import("components/views/TermsAndConditions").then(({ TermsAndConditions }) => ({default: TermsAndConditions})));
const PrivacyPolicy = lazy(() => import("components/views/PrivacyPolicy").then(({ PrivacyPolicy }) => ({default: PrivacyPolicy})));
const CookiesPolicy = lazy(() => import("components/views/CookiesPolicy").then(({ CookiesPolicy }) => ({default: CookiesPolicy})));
const MethodIndividualDonation = lazy(() => import("components/views/MethodIndividualDonation").then(({ MethodIndividualDonation }) => ({default: MethodIndividualDonation})));
const Login = lazy(() => import("components/views/Login").then(({ Login }) => ({default: Login})));
const Register  = lazy(() => import("components/views/Register").then(({ Register }) => ({default: Register})));
const ContactForm = lazy(() => import("components/molecules/ContactForm").then(({ ContactForm }) => ({ default: ContactForm })));
const Profile = lazy(() => import("components/views/Profile").then(({ Profile }) => ({default: Profile})));
const IndividualsDonations = lazy(() => import("components/views/Donations/IndividualsDonations").then(({ IndividualsDonations }) => ({default: IndividualsDonations})));
const Sponsorship  = lazy(() => import("components/views/Donations/Sponsorship").then(({ Sponsorship }) => ({default: Sponsorship})));
const Partnership = lazy(() => import("components/views/Donations/Partnership").then(({ Partnership }) => ({default: Partnership})));
const Grants = lazy(() => import("components/views/Donations/Grants").then(({ Grants }) => ({default: Grants})));
const News = lazy(() => import("components/views/News").then(({ News }) => ({default: News})));
const PageNotFound = lazy(() => import("components/views/PageNotFound").then(({ PageNotFound }) => ({default: PageNotFound})));

const App = () => {
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <AuthProvider>
      <Router>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/members/dreamer" element={<Dreamer />}/>
          <Route path="/members/mentor" element={<Mentor />}/>
          <Route path="/members/volunteer" element={<Volunteer />}/>
          <Route path="/donations" element={<Donations />} />
          <Route
            path="/donations/individuals-donations"
            element={<IndividualsDonations />}
          />
          <Route path="/donations/sponsorship" element={<Sponsorship />} />
          <Route path="/donations/partnership" element={<Partnership />} />
          <Route path="/donations/grants" element={<Grants />} />
          <Route path="/donations/individuals-donations/method" element={<MethodIndividualDonation />} />
          <Route path="/donations/individuals-donations/method/payment-details" element={<PaymentDetails />} />
          <Route
            path="/partners-and-sponsors"
            element={<PartnersAndSponsors />}
          />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route
            path="/work-with-us/our-programs"
            element={<OurPrograms />}
          />
          <Route path="/events" element={<EventsView />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/news" element={<News />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/Contact-Form" element={<ContactForm />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<PageNotFound />} />
      </Routes>
        <Footer />
      </Router>
      </AuthProvider>
    </Suspense>
  );
};

export default App;
