import React from 'react';
import { useTranslation } from 'react-i18next';
import { CDN_HOST } from 'env/env.dev';

import './EventsHero.scss';

const EventsHero = ({ title, textOne, textTwo, image, readMoreLink }) => {
  const { t } = useTranslation(["events"]);

  return (
    <section className="EventsHero">
      <div className="wrapper">
        <h2>{title || t("eventsTitle")}</h2>
        <div className="EventsHero__content">
          <p>{textOne || t("eventsTextOne")}</p>
          <p>{textTwo || t("eventsTextTwo")}</p>
          {readMoreLink && (
            <a href={readMoreLink} className="EventsHero__read-more" target="_blank" rel="noopener noreferrer">
              {t("readMoreLink")}
            </a>
          )}
        </div>

        <figure className="EventsHero__image">
          <img src={image || `${CDN_HOST}/images/events-section.png`} alt="Our Events" width="540" height="422" loading="lazy" />
        </figure>
      </div>
    </section>
  );
};

export default EventsHero;

