import React from 'react';
import { useTranslation } from 'react-i18next';
import { CDN_HOST } from 'env/env.dev';

import './Hero.scss';

const Hero = () => {

    const { t } = useTranslation(["homeTranslations"]);
    return (
        <section className="Hero">
            <div className="wrapper">
                <div className="hero__text-content">
                    <h2>{t("homePreTitle")}</h2>
                    <p>{t("heroTextOne")}</p>
                    <p>{t("heroTextTwo")}</p>
                </div>
                <figure className="hero__image">
                    <img src={ `${CDN_HOST}/images/hero-image.png` } alt="diverse group of teenegers" width="280" height="220" loading="lazy"/>
                </figure>
            </div>
        </section>
    );
};

export default Hero;